<template>
  <div>
    <div class="back" v-resize="onResize" v-if="done">
      <v-app id="inspire" style="background: transparent" :style="cssProps">
        <AppBar
          v-if="
            $route.path != '/gametype' ||
              $route.name != 'Sport' ||
              $route.name != 'Sport' ||
              size > 960
          "
          @navIcon="navDrawer"
          @login="dLogin"
          @register="dRegister"
        ></AppBar>
        <drawer :drawer="drawer" @update="update"></drawer>
        <v-main
          :style="
            ($route.name == 'Sport' ||
              $route.name == 'Inhsport' ||
              $route.name == 'Juego') &&
            size < 960
              ? 'padding-top:0'
              : ''
          "
        >
          <v-container
            :class="
              routeName == 'Index' || routeName == 'Promociones'
                ? 'pa-0'
                : size < 960
                ? 'pt-2'
                : 'pr-10'
            "
            fluid
            :style="
              $route.path != '/bet'
                ? `background:${colors.background}`
                : `height:calc(100vh - 64px);background:${colors.background}`
            "
          >
            <v-snackbar
              :timeout="-1"
              v-model="snackbarVisible"
              :value="true"
              absolute
              top
              center
              color="red darken-3"
              elevation="24"
              >¡¡¡ Prueba nuestra <strong>Aplicacion Movil !!!</strong>
              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="installPWA">
                  Instalar
                  <v-icon right>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
            </v-snackbar>
            <router-view></router-view>
          </v-container>
        </v-main>
        <v-bottom-navigation
          class="mb-13 pt-0"
          v-if="size < 960 && $store.state.token != null && 1 == 2"
          background-color="black"
          dark
          app
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loadingSaldo"
                active-class="v-btn--active"
                rounded
                text
                dark
                v-on="on"
                depressed
                class="mt-0"
                style="border-top-right-radius: 4px"
              >
                <div class="d-flex align-center mt-0">
                  <div class="d-block">
                    <span
                      class="d-flex"
                      style="line-height: 1.4; font-weight: 500"
                      >{{ currentBalance.toLocaleString() }} UND</span
                    >
                    <small
                      class="d-block tex"
                      style="
                        font-weight: 500;

                        line-height: 1.2;

                        font-size: 10px;
                      "
                      >Bono: {{ currentBono.toLocaleString() }} UND</small
                    >
                  </div>
                  <!-- <v-icon class="ml-1" dark>mdi-menu-up</v-icon> -->
                </div>
              </v-btn>
            </template>
            <v-list dense class="text-left">
              <v-subheader>CARTERAS</v-subheader>
              <v-list-item-group
                v-model="saldoSelected"
                :color="colors.gradientBarra[0]"
              >
                <v-list-item
                  two-line
                  v-for="(saldo, idx) in saldos"
                  :key="idx"
                  @click="selectSaldo(saldo.idmoneda)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ saldo.simbolo }} ({{
                        saldo.moneda
                      }})</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ saldo.balance }} UND</v-list-item-subtitle
                    >
                    <v-list-item-subtitle>
                      <small>BONO: ({{ saldo.bono }} UND)</small>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                active-class="v-btn--active"
                rounded
                text
                dark
                v-on="on"
                depressed
                class
                style="border-top-right-radius: 4px"
              >
                <div class="d-flex align-center">
                  <v-icon color="#fff" dark size="28"
                    >mdi-account-circle</v-icon
                  >

                  <div class="d-block ml-1">
                    <!-- <span

class

style="color:#fff;font-size:13px;text-transform:capitalize;line-height: 1.4;font-weight:500;"

										>{{$store.state.saldo.toLocaleString()}} UND</span>-->
                    <!-- <small

class="d-block text-left"

style="color:#fff;font-weight:500;line-height: 1.2;font-size:11px"

										>bono UND</small>-->
                  </div>
                  <!-- <v-icon dark>mdi-menu-up</v-icon> -->
                </div>
              </v-btn>
            </template>
            <v-list dense class="text-center">
              <v-list-item>
                <v-list-item-title>
                  <div class="d-flex align-center">
                    <v-icon size="28">mdi-account-circle</v-icon>
                    <span
                      class="ml-2 text-uppercase"
                      style="
                        font-size: 13px;

                        text-transform: capitalize;

                        line-height: 1.4;

                        font-weight: 500;
                      "
                      >{{ $store.state.nombre }}</span
                    >
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="$router.push('/profile')"
                  >PERFIL</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="dialogProgramas = true"
                  >PROGRAMAS</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="dialogRules = true"
                  >REGLAS</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>SALIR</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-bottom-navigation>
        <v-bottom-navigation
          :class="
            $route.name == 'Juego' || $route.path == '/gametype'
              ? 'd-none'
              : 'justify-space-between'
          "
          :background-color="colors.header"
          :style="
            colors.gradientHeader
              ? `background-image: linear-gradient(40deg, ${colors.gradientHeader[1]} 0%, ${colors.gradientHeader[0]} 100%);`
              : ''
          "
          dark
          app
          v-if="size < 960 && $store.state.token != null"
          grow
        >
          <!-- <v-btn
            v-if="
              $route.path == '/bet' ||
                $route.name == 'Juego' ||
                $route.path == '/profile' ||
                $route.path == '/sport' ||
                $route.path == '/inh' ||
                $route.path == '/casino'
            "
            value="jugar"
            to="/marcas"
          >
            <span style="font-size: 12px; line-height: 1.2; font-weight: 500"
              >MARCAS</span
            >
            <v-icon>mdi-horseshoe</v-icon>
          </v-btn> -->
          <v-btn
            v-if="
              $route.name == 'Juego' ||
                $route.path == '/profile' ||
                $route.path == '/sport' ||
                $route.path == '/bet' ||
                $route.path == '/inh'
            "
            value="jugar"
            to="/macuare"
            small
          >
            <span style="font-size: 12px;  font-weight: 500">MACUARE</span>
            <v-icon>mdi-horse</v-icon>
          </v-btn>
          <v-btn
            v-if="
              ($route.path == '/bet' ||
                $route.name == 'Juego' ||
                $route.path == '/profile' ||
                $route.path == '/casino') &&
                this.$store.state.idmoneda == 1
            "
            value="jugar"
            to="/nacionales"
            small
          >
            <span style="font-size: 12px;  font-weight: 500">NACIONALES</span>
            <v-icon>mdi-horse-variant-fast</v-icon>
          </v-btn>
          <v-btn
            v-if="
              $route.path == '/bet' ||
                $route.path == '/nacionales' ||
                $route.name == 'Juego' ||
                $route.name == 'Tycg' ||
                $route.path == '/profile' ||
                $route.path == '/sport' ||
                $route.path == '/inh' ||
                $route.path == '/casinoindex' ||
                $route.path == '/macuare' ||
                $route.path == '/concurso' ||
                $route.path == '/evenbet' ||
                $route.name == 'Virtuales'
            "
            value="jugar"
            to="/casino"
            small
          >
            <span style="font-size: 12px; font-weight: 500">CASINO</span>
            <v-icon>mdi-poker-chip</v-icon>
          </v-btn>
          <v-btn
            v-if="
              ($route.path == '/bet' ||
                $route.path == '/nacionales' ||
                $route.name == 'Juego' ||
                $route.name == 'Tycg' ||
                $route.path == '/marcas' ||
                $route.path == '/profile' ||
                $route.path == '/inh' ||
                $route.path == '/casino' ||
                $route.path == '/casinoindex' ||
                $route.path == '/macuare' ||
                $route.path == '/evenbet' ||
                $route.name == 'Virtuales') &&
                ($store.state.idsb == 13 || $store.state.idsb == 8)
            "
            value="jugar"
            to="/sport"
            small
          >
            <span style="font-size: 12px; line-height: 1.2; font-weight: 500"
              >PARLEY</span
            >
            <v-icon>mdi-baseball-diamond</v-icon>
          </v-btn>
          <v-btn
            v-if="
              ($route.path == '/casino' ||
                $route.path == '/nacionales' ||
                $route.name == 'Juego' ||
                $route.name == 'Tycg' ||
                $route.path == '/profile' ||
                $route.path == '/sport' ||
                $route.path == '/casinoindex' ||
                $route.path == '/macuare' ||
                $route.path == '/concurso' ||
                $route.path == '/evenbet' ||
                $route.name == 'Virtuales') &&
                $store.state.pais_user == 'Venezuela'
            "
            value="jugar"
            to="/inh"
          >
            <span style="font-size: 12px; line-height: 1.2; font-weight: 500"
              >HIPICO</span
            >
            <v-icon>mdi-horse-variant</v-icon>
          </v-btn>

          <v-btn
            v-if="
              ($route.path == '/casino' ||
                $route.path == '/nacionales' ||
                $route.name == 'Juego' ||
                $route.name == 'Tycg' ||
                $route.path == '/profile' ||
                $route.path == '/sport' ||
                $route.path == '/casinoindex' ||
                $route.path == '/macuare' ||
                $route.path == '/concurso' ||
                $route.path == '/evenbet' ||
                $route.name == 'Virtuales') &&
                $store.state.pais_user != 'Venezuela'
            "
            value="jugar"
            to="/bet"
            small
          >
            <span style="font-size: 12px; line-height: 1.2; font-weight: 500"
              >HIPICO</span
            >
            <v-icon>mdi-horse-variant</v-icon>
          </v-btn>

          <!--	<v-btn
						v-if="
              $route.path == '/casino' ||
              $route.name == 'Juego' ||
              $route.path == '/profile' ||
              $route.path == '/sport' ||
              $route.path == '/bet' && ($store.state.idmoneda == 1)
            "
						value="jugar"
						to="/inh"
					>
						<span>INH</span>
						
					</v-btn>-->

          <!-- <v-btn
            v-if="
              $route.path != '/casino' &&
                $route.name != 'Juego' &&
                $route.path != '/profile' &&
                $route.path != '/sport' &&
                $route.path != '/bet' &&
                $route.path != '/inh' &&
                $route.path != '/marcas'
            "
            value="canales"
            to="/channels"
            @click="dialogCanales = true"
          >
            <span>CANALES</span>
          
          </v-btn> -->

          <!-- <v-btn
            v-if="$store.state.premios.premio"
            class="mx-0"
            depressed
            to="/profile"
          >
            
            <v-badge
              v-if="$store.state.premios.jugadas != '0'"
              bordered
              color="red"
              :content="$store.state.premios.jugadas"
              :value="$store.state.premios.jugadas"
              class="t-bag animate"
            >
              <b
                style="
                  color: red;

                  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
                    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
                "
                >{{ $store.state.premios.premio }} UND</b
              >
            </v-badge>
          </v-btn> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="loadingSaldo"
                active-class="v-btn--active"
                rounded
                text
                dark
                v-on="on"
                depressed
                class
                style="border-top-right-radius: 4px"
              >
                <div class="d-flex align-center">
                  <div class="d-block">
                    <span
                      class="d-flex"
                      style="line-height: 1.4; font-weight: 500"
                      >{{ currentBalance.toLocaleString() }} UND</span
                    >
                    <small
                      class="d-block tex"
                      style="
                        font-weight: 500;

                        line-height: 1.2;

                        font-size: 10px;
                      "
                      >Bono: {{ currentBono.toLocaleString() }} UND</small
                    >
                  </div>
                  <!-- <v-icon class="ml-1" dark>mdi-menu-up</v-icon> -->
                </div>
              </v-btn>
            </template>
            <v-list dense class="text-left">
              <v-subheader>CARTERAS</v-subheader>
              <v-list-item-group
                v-model="saldoSelected"
                :color="colors.gradientBarra[0]"
              >
                <v-list-item
                  two-line
                  v-for="(saldo, idx) in saldos"
                  :key="idx"
                  @click="selectSaldo(saldo.idmoneda)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ saldo.simbolo }} ({{
                        saldo.moneda
                      }})</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{ saldo.balance }} UND</v-list-item-subtitle
                    >
                    <v-list-item-subtitle>
                      <small>BONO: ({{ saldo.bono }} UND)</small>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                active-class="v-btn--active"
                rounded
                text
                dark
                v-on="on"
                depressed
                class
                style="border-top-right-radius: 4px"
              >
                <div class="d-flex align-center">
                  <v-icon color="#fff" dark size="28">mdi-home</v-icon>

                  <div class="d-block ml-1">
                    <!-- <span

class

style="color:#fff;font-size:13px;text-transform:capitalize;line-height: 1.4;font-weight:500;"

										>{{$store.state.saldo.toLocaleString()}} UND</span>-->
                    <!-- <small

class="d-block text-left"

style="color:#fff;font-weight:500;line-height: 1.2;font-size:11px"

										>bono UND</small>-->
                  </div>
                  <!-- <v-icon dark>mdi-menu-up</v-icon> -->
                </div>
              </v-btn>
            </template>
            <v-list dense class="text-center">
              <v-list-item>
                <v-list-item-title @click="$router.push('/gametype')">
                  <div class="d-flex align-center">
                    <v-icon size="28">mdi-home</v-icon>
                    <span
                      class="ml-2 text-uppercase"
                      style="
                        font-size: 14;
                        color: green;
                        

                        text-transform: capitalize;

                        line-height: 1.4;

                        font-weight: 700;
                      "
                      >Inicio</span
                    >
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="$router.push('/profile')"
                  >PERFIL</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="dialogProgramas = true"
                  >PROGRAMAS</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title @click="dialogRules = true"
                  >REGLAS</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-title>SALIR</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <span>REGLAS</span> -->
          <!-- <v-icon>mdi-map-marker</v-icon> -->
        </v-bottom-navigation>
        <template v-if="$route.name != 'Juego' || $route.name != 'Sport'">
          <!-- <v-footer color="white" padless class="d-block" v-if="footer">
            <v-row
              no-gutters
              :style="`background:${colors.header}`"
              class="py-4"
            >
              <v-col cols="12" lg="3" class="d-flex">
                <v-toolbar-title
                  class="mb-4 mx-auto mx-lg-3 d-lg-flex align-center"
                  style="cursor: pointer"
                >
                  <v-img
                    class="mx-auto"
                    :src="logo"
                    max-width="240"
                    contain
                  ></v-img>
                </v-toolbar-title>
              </v-col>
              <v-col cols="6" lg="3">
                <v-list
                  dense
                  :dark="marca == 'Betgana Races'"
                  class="pa-0 ma-0"
                  color="transparent"
                >
                  <v-list-item @click="$router.push('/terminos')">
                    <v-list-item-content>
                      <v-list-item-title
                        >Terminos y condiciones</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$router.push('/depositos')">
                    <v-list-item-content>
                      <v-list-item-title
                        >Politicas de Depositos y Retiros</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                   <v-list-item @click="$router.push('/promoflash')">
                    <v-list-item-content>
                      <v-list-item-title
                        >TyC Desafío del Más Allá</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item> 
                  <v-list-item @click="$router.push('/promociones')">
                    <v-list-item-content>
                      <v-list-item-title>Promociones</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="6" lg="3">
                <v-list
                  :dark="marca == 'Betgana Races'"
                  dense
                  class="pa-0 ma-0"
                  color="transparent"
                >
                  <v-list-item
                    v-if="$store.state.social && $store.state.social.facebook"
                    @click="open($store.state.social.facebook)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-facebook</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Facebook</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.social && $store.state.social.twitter"
                    @click="open($store.state.social.twitter)"
                  >
                    <v-list-item-icon>
                      <v-img
                        height="25"
                        width="25"
                        src="https://images.email-platform.com/simpletv/twitter-x-logo-black-rounded-outline-2085022(1).png"
                      ></v-img>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>X</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.social && $store.state.social.instagram"
                    @click="open($store.state.social.instagram)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-instagram</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Instagram</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.state.social && $store.state.social.youtube"
                    @click="open($store.state.social.youtube)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-whatsapp</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Whatsapp</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" lg="3" class="d-flex">
                <v-toolbar-title
                  class="mb-4 mx-auto mx-lg-3 d-lg-flex align-center"
                  style="cursor: pointer"
                >
                  <span class="mt-2 t-title ml-2 text-uppercase"
                    >Autorizado por INH</span
                  >
                  <v-img
                    class="mx-auto"
                    :src="require('./assets/img/inh_logo.png')"
                    max-width="80"
                    contain
                  ></v-img>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row
              justify="center"
              no-gutters
              :class="size < 960 ? 'mb-12 pb-2' : ''"
            >
              <v-col
                class="py-1 text-center white--text"
                :style="`background:${colors.header}`"
                cols="12"
              >
                <strong
                  :class="
                    marca == 'Betgana Races' ? 'white--text' : 'black--text'
                  "
                  class="text-uppercase"
                >
                  © {{ new Date().getFullYear() }} —
                  {{ marca }}
                </strong>
              </v-col>
            </v-row>
          </v-footer> -->
          <v-footer color="#02021B">
            <v-container color="#02021B">
              <v-row class="d-flex flex-wrap justify-space-between">
                <!-- Logo de la Marca -->
                <v-col
                  cols="12"
                  md="4"
                  lg="4"
                  class="d-flex justify-start mb-4 mb-md-0"
                >
                  <v-img
                    src="https://trackandraces.com/dominio/tr/logotrsb.png"
                    alt="Logo de la Marca"
                    max-height="80"
                    contain
                  ></v-img>
                </v-col>

                <!-- Redes Sociales -->
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  sm="6"
                  class="d-flex justify-center"
                >
                  <v-btn
                    icon
                    class="white--text"
                    v-if="$store.state.social && $store.state.social.facebook"
                    @click="open($store.state.social.facebook)"
                  >
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="white--text"
                    v-if="$store.state.social && $store.state.social.instagram"
                    @click="open($store.state.social.instagram)"
                  >
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="white--text"
                    v-if="$store.state.social && $store.state.social.twitter"
                    @click="open($store.state.social.twitter)"
                  >
                    <template>
                      <freshworks-widget />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                      >
                        <g
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                          color="currentColor"
                        >
                          <path
                            d="m7 17l4.194-4.193M17 7l-4.193 4.194m0 0L9.777 7H7l4.194 5.807m1.612-1.614L17 17h-2.778l-3.028-4.193"
                          />
                          <path
                            d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10"
                          />
                        </g>
                      </svg>
                    </template>
                  </v-btn>
                  <v-btn
                    icon
                    class="white--text"
                    href="https://www.youtube.com/@trackandraces"
                    target="_blank"
                  >
                    <v-icon>mdi-youtube</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>
              <!-- Links Importantes -->
              <v-row>
                <v-col cols="12" md="3" sd="6" class="mb-4 mb-md-0">
                  <h4 class="info--text">PROTECCION AL JUGADOR</h4>
                  <v-list color="#02021B" dense>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/tyc' }"
                    >
                      <v-list-item-title class="white--text"
                        >Términos y Condiciones</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/kyc' }"
                    >
                      <v-list-item-title class="white--text"
                        >Política de KYC
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/jr' }"
                    >
                      <v-list-item-title class="white--text"
                        >Juego Responsable</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/ae' }"
                    >
                      <v-list-item-title class="white--text"
                        >Autoexclusión</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/pp' }"
                    >
                      <v-list-item-title class="white--text"
                        >Politica de Privacidad</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      @click="router - link"
                      :to="{ path: '/tycg/rc' }"
                    >
                      <v-list-item-title class="white--text"
                        >Resolucion de Conflictos</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3" sd="6" class="mb-4 mb-md-0">
                  <h4 class="info--text">JUEGOS</h4>
                  <v-list color="#02021B" dense>
                    <v-list-item>
                      <v-list-item-title class="white--text"
                        >Hipica</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="white--text"
                        >Deportes</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="white--text"
                        >Casino en Vivo</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="white--text"
                        >Maquinitas</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="white--text"
                        >Virtual Gamming</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3" sd="6" class="mb-4 mb-md-0">
                  <h4 class="info--text">PROMOCIONES</h4>
                  <v-list color="#02021B" dense>
                    <v-list-item @click="$router.push('/promociones')">
                      <v-list-item-title class="white--text"
                        >Escala de Dividendos</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="$router.push('/promociones')">
                      <v-list-item-title class="white--text"
                        >30% Adicional 1er Deposito</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="$router.push('/promociones')">
                      <v-list-item-title class="white--text"
                        >Gana por Referidos</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3" sd="6" class="mb-4 mb-md-0">
                  <h4 class="info--text">INFORMACION GENERAL</h4>
                  <v-list color="#02021B" dense>
                    <v-list-item @click="$router.push('/depositos')">
                      <v-list-item-title class="white--text"
                        >Politicas de Deposito y Retiros</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="$router.push('/terminos')">
                      <v-list-item-title class="white--text"
                        >Reglas Hipismo y Deportes</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="$router.push('/terminospoker')">
                      <v-list-item-title class="white--text"
                        >Reglas de Poker Online</v-list-item-title
                      >
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title class="white--text"
                        >Tutoriales</v-list-item-title
                      >
                    </v-list-item> -->
                    <v-list-item @click="open($store.state.social.youtube)">
                      <v-list-item-title class="white--text"
                        >Contactenos</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row
                v-if="dominio == 'tar.la'"
                class="d-flex justify-center align-center mt-4"
              >
                <!-- Parrafo de Licencia y Operador -->
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center mb-4 mb-md-0"
                >
                  <p class="#FAFAFA--text">
                    TAR.LA es operado por Jazz Business Solutions B.V., con el
                    número 152056 y autorizada por la Junta de Control de Juegos
                    de Curazao para ofrecer juegos de azar con el número de
                    licencia OGL/2024/400/0824
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-container>
                  <v-row class="d-flex justify-center align-center">
                    <!-- Imagen 1 -->
                    <v-col
                      v-if="
                        dominio == 'tar.la' ||
                          dominio == 'www.tar.la' 
                      "
                      cols="12"
                      md="4"
                      sm="4"
                      xs="8"
                      class="d-flex justify-center px-0"
                    >
                      <div>
                        <a
                          href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJblF5WkRaQk1uZENPVEJUU25oMldIbGhhU3RFVkhjOVBTSXNJblpoYkhWbElqb2lRMUZwYkhOc2F6UXZZWGxVT1VWWVRDdDVZWGhpWnowOUlpd2liV0ZqSWpvaU9UZGxNVGd5T0RJMU56QXlZV1F4T0dZeE5ESXpNekUwTldWa01UUXhNelE1TkdZMU5UWTJPVFZtTm1VeFlqTTROamc1TXpobVlUbGxaRFkyWXpKak1TSXNJblJoWnlJNklpSjk="
                          target="_blank"
                        >
                          <v-img
                            src="https://seal.cgcb.info/1c0246df-1aa7-485a-a24c-21ae5e730000"
                            alt="Imagen 1"
                            max-height="50"
                            contain
                          ></v-img
                        ></a>
                      </div>
                    </v-col>
                    <!-- Imagen 2 -->
                    <v-col
                      cols="12"
                      md="4"
                      sm="4"
                      xs="8"
                      class="d-flex justify-center px-0"
                    >
                      <v-img
                        :src="require('./assets/img/inh_logo.png')"
                        alt="Imagen 2"
                        max-height="50"
                        contain
                      ></v-img>
                    </v-col>
                    <!-- Imagen 3 -->
                    <v-col
                      cols="12"
                      md="4"
                      sm="4"
                      xs="8"
                      class="d-flex justify-center px-0"
                    >
                      <v-img
                        src="https://trackandraces.com/dominio/tr/adult.png"
                        alt="Imagen 3"
                        max-height="50"
                        contain
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
              <!-- Derechos Reservados -->
              <v-row class="d-flex justify-center">
                <v-col
                  v-if="
                    dominio == 'tar.la' ||
                      dominio == 'www.tar.la' 
                  "
                  cols="12"
                  md="4"
                  sm="4"
                  xs="8"
                  class="d-flex justify-center px-0"
                >
                  <div
                    id="xcm-190ad064-38a4-4a09-b70a-66ab3588750a"
                    data-xcm-seal-id="190ad064-38a4-4a09-b70a-66ab3588750a"
                    data-xcm-image-size="128"
                    data-xcm-image-type="basic-small"
                  ></div>
                </v-col>
              </v-row>

              <v-row class="d-flex justify-center">
                <v-col cols="12" class="text-center">
                  <span class="white--text"
                    >&copy; 2024 {{ dominio }} Todos los derechos
                    reservados.</span
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-footer>
        </template>
        <v-dialog v-model="dialogRules">
          <v-card>
            <v-system-bar window dark>
              <v-spacer></v-spacer>

              <span class="overline">Reglas y límites</span>
              <v-spacer></v-spacer>
              <!-- <v-icon>mdi-checkbox-blank-outline</v-icon> -->
              <v-icon
                style="position: absolute; right: 8px"
                size="24"
                @click="dialogRules = false"
                >mdi-close</v-icon
              >
            </v-system-bar>
            <!-- <v-icon

style="position:absolute;z-index:99;right:6px;top:6px"

size="40"

class

dark

@click="dialogRules = false"

						>mdi-close</v-icon>-->
            <!-- <v-img src="./assets/img/reglas.png"></v-img> -->
            <div :style="`background:${colors.background}`">
              <div class="d-flex">
                <span
                  :style="`width:100%;background:${colors.primary}`"
                  class="text-center black--text caption"
                  >Tickets caducan a los 8 días / Todo Hipodromo que no aparezca
                  en los listados se considera Clase C</span
                >
              </div>
              <div>
                <v-row no-gutters>
                  <v-col cols="12" lg="6" class="px-3">
                    <v-card class="mt-3">
                      <v-card-title
                        :style="
                          colors.gradient
                            ? `background: linear-gradient(0deg, ${colors.gradient[1]} 0%, ${colors.gradient[0]} 100%)`
                            : ''
                        "
                        class="py-1 white--text"
                        >Clase A</v-card-title
                      >
                      <v-card-text ref="a" class="px-0 pb-0">
                        <v-row no-gutters>
                          <v-col lg="5" class="mr-lg-3">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Ganador - Place - Show</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle class="caption">
                                  <small>No hay límites de dividendos</small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Quiniela - Exacta - Pick 2</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold caption"
                                    >10.000 UND por ticket</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                  <small>
                                    De no haber ticket ganador, se pagará
                                    <b>500 x 1 UND</b>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase">Trifecta</b>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold caption"
                                    >15.000 UND por ticket</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                  <small>
                                    De no haber ticket ganador, se pagará
                                    <b>3.000 x 1 UND</b>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase">Pick 3</b>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold caption"
                                    >20.000 UND por ticket</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                  <small>
                                    De no haber ticket ganador, se pagará
                                    <b>3.000 x 1 UND</b>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase">Pick 4</b>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold caption"
                                    >25.000 UND por ticket</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                  <small>
                                    De no haber ticket ganador,se pagará
                                    <b>4.500 x 1 UND</b>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase">Superfecta</b>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold caption"
                                    >25.000 UND por ticket</span
                                  >
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                  <small>
                                    De no haber ticket ganador, se pagará
                                    <b>4.500 x 1 UND</b>
                                  </small>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col :style="`background:${colors.perfil}`">
                            <ul class="py-2 white--text">
                              <li>Aqueduct</li>
                              <li>Belmont Park</li>
                              <li>Saratoga</li>
                              <li>Santa Anita</li>
                              <li>Del Mar</li>
                              <li>Hollywood Park</li>
                              <li>
                                Gulftream Park (CONDICION ESPECIAL CATEGORIA “A”
                                SOLO LOS MESES DE NOV-DIC-ENE-FEB-MAR.)
                              </li>
                              <li>Keeneland</li>
                              <li>Kentucky Derby</li>
                              <li>Dubai World Cup</li>
                              <li>Breeder´s Cup</li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="6" class="px-3 px-lg-0 pr-lg-3">
                    <v-card class="mt-3">
                      <v-card-title
                        :style="
                          colors.gradient
                            ? `background: linear-gradient(0deg, ${colors.gradient[1]} 0%, ${colors.gradient[0]} 100%)`
                            : ''
                        "
                        class="py-1 white--text"
                        >Clase B</v-card-title
                      >
                      <v-card-text class="px-0 pb-0">
                        <v-row no-gutters>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Ganador - Place - Show</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Ganador:
                                  <b>30 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Place:
                                  <b>12 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Show:
                                  <b>6 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Quiniela - Exacta - Pick 2</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>300 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Trifecta - Pick 3</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>2.500 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>15.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Superfecta - Pick 4</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>4.000 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>20.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col ref="b" :style="`background:${colors.perfil}`">
                            <ul class="py-2 white--text">
                              <li>Arlington</li>
                              <li>Parx Racing</li>
                              <li>Monmouth Park</li>
                              <li>Fair Grounds</li>
                              <li>Pimlico</li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="6" class="px-3">
                    <v-card class="mt-3 mb-3">
                      <v-card-title
                        :style="
                          colors.gradient
                            ? `background: linear-gradient(0deg, ${colors.gradient[1]} 0%, ${colors.gradient[0]} 100%)`
                            : ''
                        "
                        class="py-1 white--text"
                        >Clase C</v-card-title
                      >
                      <v-card-text ref="c" class="px-0 pb-0">
                        <v-row no-gutters>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Ganador - Place - Show</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Ganador:
                                  <b>20 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Place:
                                  <b>8 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Show:
                                  <b>4 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Quiniela - Exacta - Pick 2</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Quinela:
                                  <b>150 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Exacta:
                                  <b>300 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Pick 2:
                                  <b>300 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Trifecta - Pick 3</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>2.000 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Superfecta - Pick 4</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>3.000 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col :style="`background:${colors.perfil}`">
                            <ul class="py-3 white--text">
                              <li>Laurel Park</li>
                              <li>Churchill Downs</li>
                              <li>Ellis Park</li>
                              <li>Golden Gates</li>
                              <li>Indiana Grand</li>
                              <li>Gulftrean West</li>
                              <li>Mountainner</li>
                              <li>Evangeline</li>
                              <li>Charles Town</li>
                              <li>Presque Isle</li>
                              <li>Finger Lakes</li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" lg="6" class="px-3 px-lg-0 pr-lg-3">
                    <v-card class="mt-3">
                      <v-card-title
                        :style="
                          colors.gradient
                            ? `background: linear-gradient(0deg, ${colors.gradient[1]} 0%, ${colors.gradient[0]} 100%)`
                            : ''
                        "
                        class="py-1 white--text"
                        >Clase D</v-card-title
                      >
                      <v-card-text class="px-0 pb-0">
                        <v-row no-gutters>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Ganador - Place - Show</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Ganador:
                                  <b>15 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Place:
                                  <b>8 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Show:
                                  <b>4 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>10.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Quiniela - Exacta - Pick 2</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Quinela:
                                  <b>100 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Exacta:
                                  <b>100 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Pick 2:
                                  <b>150 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>2.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Trifecta - Pick 3</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>1.000 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>3.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line>
                              <v-list-item-content>
                                <v-list-item-title class="caption">
                                  <b class="text-uppercase"
                                    >Superfecta - Pick 4</b
                                  >
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Dividendo máximo:
                                  <b>1.500 X 1 UND</b>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-weight-medium caption"
                                >
                                  Máximo pago por apuesta:
                                  <b>5.000 UND</b>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col ref="d" :style="`background:${colors.perfil}`">
                            <ul class="py-3 white--text">
                              <li>Derby Lane</li>
                              <li>Palm Beach</li>
                              <li>Daytona</li>
                              <li>Jacksonville</li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogLogin" max-width="720">
          <v-row style="background: #fff" class="mx-0">
            <v-col cols="12" sm="6">
              <v-card flat>
                <v-card-text>
                  <v-card-title class="d-flex">
                    <span class="mx-auto">INICIAR SESIÓN</span>
                  </v-card-title>
                  <v-text-field
                    :color="colors.perfil"
                    v-if="dialogLogin"
                    autofocus
                    class="mt-4 mb-3"
                    label="E-mail"
                    single-line
                    dense
                    solo
                    flat
                    background-color="blue-grey lighten-5"
                    hide-details
                    prepend-inner-icon="mdi-email"
                    v-model="email"
                  ></v-text-field>

                  <v-text-field
                    :color="colors.perfil"
                    v-model="pass"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Contraseña"
                    single-line
                    dense
                    solo
                    flat
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    @keyup.enter.native="login()"
                  ></v-text-field>

                  <v-btn
                    class="mb-2 mt-8"
                    :color="colors.perfil"
                    :loading="loading"
                    depressed
                    dark
                    block
                    @click="login()"
                    >Iniciar sesión</v-btn
                  >
                  <v-divider class="my-4"></v-divider>
                  <span class="text-center d-block mt-4">
                    ¿Olvidaste tu contraseña?
                    <a
                      @click="
                        dialogReset = true;

                        dialogLogin = false;
                      "
                      >Haz click aqui</a
                    >
                  </span>
                  <span class="text-center d-block mb-4">
                    Contactanos a
                    <b>{{ emailtr }}</b>
                  </span>
                  <div v-if="error">
                    <v-alert class="mb-0 mt-0" type="error">{{
                      mensaje
                    }}</v-alert>
                    <br />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" class="d-none d-sm-flex pa-0">
              <div class="ma-auto">
                <div class="d-block">
                  <h1 class="text-center">{{ marca }}</h1>
                  <h6 class="text-center mt-1 caption">
                    Ingrese sus credenciales para acceder
                  </h6>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-dialog>

        <v-dialog v-model="dialogRegister" max-width="440">
          <form id="frmregister" @submit="register" autocomplete="nunca">
            <v-row style="background: #fff" class="mx-0">
              <v-col cols="12">
                <v-card flat>
                  <v-card-text>
                    <v-card-title class="d-flex pt-0">
                      <span class="mx-auto">REGISTRATE</span>
                    </v-card-title>
                    <v-text-field
                      :color="colors.perfil"
                      v-if="dialogRegister"
                      autofocus
                      class="mt-0 mb-3"
                      label="E-mail"
                      autocomplete="nunca"
                      :rules="[rules.required, rules.emailValid]"
                      dense
                      outlined
                      flat
                      background-color="blue-grey lighten-5"
                      prepend-inner-icon="mdi-email"
                      v-model="emailRe"
                      required
                      type="email"
                    ></v-text-field>
                    <v-text-field
                      :color="colors.perfil"
                      v-model="passRe"
                      background-color="blue-grey lighten-5"
                      prepend-inner-icon="mdi-lock"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show1 ? 'text' : 'password'"
                      autocomplete="off"
                      dense
                      outlined
                      flat
                      label="Contraseña"
                      class="mt-0 mb-3"
                      hint="Minimo 8 characters"
                      counter
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <!-- <div class="d-flex">
                      <v-text-field
                      hint="At least 8 characters"
                        :color="colors.perfil"
                        v-model="passRe"
                        background-color="blue-grey lighten-5"
                        
                        label="Contraseña"
                        autocomplete="off"
                        single-line
                        dense
                        filled
                        flat
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        :rules="[rules.required, rules.min]"
                        @click:append="show1 = !show1"
                        class="mt-0 mb-3 register"
                        required
                      ></v-text-field>
                      <v-text-field
                        :color="colors.perfil"
                        v-model="passConRe"
                        background-color="blue-grey lighten-5"
                        hide-details
                        label="Confirmar contraseña"
                        single-line
                        dense
                        solo
                        flat
                        prepend-inner-icon="mdi-lock"
                        type="password"
                        required
                        class="ml-3 mt-0 mb-3 register"
                      ></v-text-field>
                    </div> -->
                    <div class="d-flex">
                      <v-text-field
                        :color="colors.perfil"
                        autocomplete="off"
                        class="mt-0 mb-3 register"
                        label="Nombre"
                        single-line
                        dense
                        solo
                        flat
                        background-color="blue-grey lighten-5"
                        hide-details
                        prepend-inner-icon="mdi-account-circle"
                        v-model="nombre"
                        required
                      ></v-text-field>
                      <v-text-field
                        :color="colors.perfil"
                        autocomplete="off"
                        class="ml-3 mt-0 mb-3 register"
                        label="Apellido"
                        single-line
                        dense
                        solo
                        flat
                        background-color="blue-grey lighten-5"
                        hide-details
                        v-model="apellido"
                        required
                      ></v-text-field>
                    </div>
                    <v-text-field
                      :color="colors.perfil"
                      autocomplete="off"
                      class="mb-2 register"
                      label="# Documento de Indentidad"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      prepend-inner-icon="mdi-card-account-details"
                      v-model="dniRe"
                      required
                      type="text"
                    ></v-text-field>
                    <p class="ma-0">Fecha de nacimiento</p>
                    <v-text-field
                      :color="colors.perfil"
                      autocomplete="off"
                      class="mb-3 register"
                      label="F. Nacimiento"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      prepend-inner-icon="mdi-calendar"
                      v-model="nacimientoRe"
                      required
                      type="date"
                    ></v-text-field>

                    <div class="d-flex">
                      <vue-tel-input
                        @input="onInput"
                        :required="true"
                        :preferred-countries="['ve', 'pe']"
                        class="mb-3 mr-3 phone"
                        placeholder="Teléfono"
                        v-model="phoneRe"
                      ></vue-tel-input>
                      <v-select
                        :color="colors.perfil"
                        class="mb-3 register"
                        label="Moneda"
                        single-line
                        dense
                        solo
                        flat
                        background-color="blue-grey lighten-5"
                        hide-details
                        v-model="moneda"
                        required
                        item-text="moneda"
                        item-value="idmoneda"
                        :items="contryCurrency"
                        :rules="[(v) => !!v || 'Este campo es requerido']"
                      >
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.simbolo }} ({{ data.item.moneda }})
                          {{
                            data.item.paisnombre == "ECUADOR"
                              ? "- ECUADOR"
                              : data.item.paisnombre == "EEUU"
                              ? "- EEUU"
                              : ""
                          }}
                        </template>
                      </v-select>
                    </div>
                    <!-- <v-text-field

:color="colors.perfil"

v-model="phoneRe"

background-color="blue-grey lighten-5"

hide-details

label="Teléfono"

single-line

dense

solo

flat

prepend-inner-icon="mdi-phone"

type="phone"

class="mb-3"

required

										></v-text-field>-->

                    <v-text-field
                      :color="colors.perfil"
                      class="mt-0 mb-3 register"
                      label="Pais"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      prepend-inner-icon="mdi-flag"
                      v-model="rePais"
                      required
                    ></v-text-field>
                    <v-text-field
                      :color="colors.perfil"
                      class="mt-0 mb-3 register"
                      label="Ciudad"
                      single-line
                      dense
                      solo
                      flat
                      background-color="blue-grey lighten-5"
                      hide-details
                      prepend-inner-icon="mdi-city"
                      v-model="reCuidad"
                      required
                    ></v-text-field>

                    <v-text-field
                      :color="colors.perfil"
                      v-model="referido"
                      background-color="blue-grey lighten-5"
                      hide-details
                      label="Codigo Referido (OPCIONAL)"
                      single-line
                      dense
                      solo
                      flat
                      prepend-inner-icon="mdi-card-account-details"
                      class="register"
                    ></v-text-field>
                    <div v-if="error">
                      <v-alert dense class="mb-0 mt-3" type="error"
                        >Verifique los datos ingresados</v-alert
                      >
                    </div>
                    <!-- <span class="red--text d-block mt-4">*Todos los campos son obligatorios</span> -->
                    <v-checkbox
                      :color="colors.perfil"
                      dense
                      hide-details
                      v-model="checkboxMayor"
                      :label="`Confirmo que soy mayor de 18 años`"
                      required
                    ></v-checkbox>
                    <v-checkbox
                      :color="colors.perfil"
                      dense
                      hide-details
                      v-model="checkboxCond"
                      :label="`Acepto los terminos y condiciones`"
                      class="mt-0 mb-3"
                      required
                    ></v-checkbox>

                    <!-- <v-text-field
                      :color="colors.perfil"
                      v-model="promoCode"
                      background-color="blue-grey lighten-5"
                      hide-details
                      label="Codigo Promocional"
                      single-line
                      dense
                      solo
                      flat
                      prepend-inner-icon="mdi-star"
                    ></v-text-field> -->
                    <v-btn
                      class="mb-2 mt-8"
                      :color="colors.perfil"
                      :loading="loading"
                      depressed
                      dark
                      block
                      type="submit"
                      >REGISTRARME</v-btn
                    >
                    <v-divider class="my-4"></v-divider>
                    <span class="text-center d-block mt-4">
                      Lee nuestros
                      <a href>terminos y condiciones</a>
                    </span>
                    <span class="text-center d-block mb-0">
                      Contactanos a
                      <b>{{ emailtr }}</b>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </form>
        </v-dialog>
        <v-dialog v-model="dialogSMS" persistent max-width="400">
          <v-card>
            <v-card-title class="d-flex">
              <span class="mr-auto body-1 font-weight-medium color"></span>
              <v-icon @click="dialogSMS = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <span class="body-1 d-block text-center">
                Hola
                <b>{{ this.nombre }},</b>
                hemos enviado un codigo a tu email y dispositivo movil.
              </span>
              <span class="body-1 d-block text-center"></span>
              <span class="body-2 mt-2 d-block text-center"
                >Por favor introduzca el codigo a continuación.</span
              >
              <form @submit="sendSMS">
                <div class="d-flex align-center">
                  <v-text-field
                    :color="colors.perfil"
                    v-model="codeSMS"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Ingrese su codigo"
                    single-line
                    dense
                    solo
                    flat
                    append-icon="mdi-code"
                    type="phone"
                    class="my-3 mr-3"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                    :dark="loading ? false : true"
                    color="blue"
                    depressed
                    >continuar</v-btn
                  >
                </div>
                <b v-if="error" class="red--text font-weight-medium ml-2" dense
                  >Codigo incorrecto</b
                >
              </form>
              <span class="title d-block text-center mt-3"
                >{{ min }} Minutos</span
              >
              <span class="body-2 d-block text-center font-weight-medium mb-3"
                >restantes para volver a solicitar</span
              >
              <v-btn
                @click="resendSMS()"
                text
                small
                block
                :disabled="min != '0:00'"
                >Volver a enviar</v-btn
              >
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReset" persistent max-width="400">
          <v-card>
            <v-card-title class="d-flex">
              <span class="mr-auto body-1 font-weight-medium color">
                <span>Recuperar contraseña</span>
              </span>
              <v-icon @click="dialogReset = false">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
              <div v-if="!waiting && !doneReset">
                <form @submit.prevent="sendReset">
                  <span class="subtitle-2"
                    >Introduce tu email para continuar</span
                  >
                  <v-text-field
                    :color="colors.perfil"
                    v-model="emialReset"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Email"
                    single-line
                    dense
                    solo
                    flat
                    append-icon="mdi-code"
                    type="email"
                    class="my-3 mr-3"
                  ></v-text-field>
                  <v-alert v-if="invalid" dense type="error">
                    Este Email no se encuentra registrado.
                    <br />Por favor verifique.
                  </v-alert>
                  <h4 class="mb-3 font-weight-regular">
                    Enviaremos un codigo a tu email y dispositivo movil
                  </h4>
                  <v-btn
                    type="submit"
                    :loading="loading"
                    block
                    :color="colors.primary"
                    dark
                    depressed
                    >Continuar</v-btn
                  >
                </form>
              </div>
              <div v-if="waiting">
                <span class="body-1 d-block text-center"
                  >Te hemos enviado un codigo a tu email y dispositivo
                  movil</span
                >
                <span class="body-1 d-block text-center"></span>
                <span class="body-2 mt-2 d-block text-center"
                  >Por favor introduzca el codigo a continuación.</span
                >
                <form @submit="sendSMS">
                  <div class="d-flex align-center">
                    <v-text-field
                      :color="colors.perfil"
                      v-model="codeSMS"
                      background-color="blue-grey lighten-5"
                      hide-details
                      label="Ingrese su codigo"
                      single-line
                      dense
                      solo
                      flat
                      append-icon="mdi-code"
                      type="phone"
                      class="my-3 mr-3"
                    ></v-text-field>
                    <v-btn
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      :dark="loading ? false : true"
                      color="blue"
                      depressed
                      >continuar</v-btn
                    >
                  </div>
                  <b
                    v-if="error"
                    class="red--text font-weight-medium ml-2"
                    dense
                    >Codigo incorrecto</b
                  >
                </form>
                <span class="title d-block text-center mt-3"
                  >{{ min }} Minutos</span
                >
                <span class="body-2 d-block text-center font-weight-medium mb-3"
                  >restantes para volver a solicitar</span
                >
                <v-btn
                  @click="resendSMS()"
                  text
                  small
                  block
                  :disabled="min != '0:00'"
                  >Volver a enviar</v-btn
                >
              </div>
              <div v-if="doneReset">
                <form @submit.prevent="changePass">
                  Por favor introduzca la nueva contraseña
                  <v-text-field
                    :color="colors.perfil"
                    v-model="passReset"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Nueva contraseña"
                    single-line
                    dense
                    solo
                    flat
                    append-icon="mdi-code"
                    type="phone"
                    class="my-3 mr-3"
                  ></v-text-field>
                  <v-text-field
                    :color="colors.perfil"
                    v-model="confPassReset"
                    background-color="blue-grey lighten-5"
                    hide-details
                    label="Confirmar contraseña"
                    single-line
                    dense
                    solo
                    flat
                    append-icon="mdi-code"
                    type="phone"
                    class="my-3 mr-3"
                  ></v-text-field>
                  <span class="text-center d-block mb-4">
                    Si tienes algun problema no dudes contactarnos en
                    <b>{{ emailtr }}</b>
                  </span>
                  <v-btn
                    type="submit"
                    block
                    :color="colors.primary"
                    dark
                    depressed
                    >Confirmar</v-btn
                  >
                </form>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar color="white" v-model="snackbar">
          <b class="green--text">{{ $store.state.msg }}</b>
          <v-btn text dark @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
      </v-app>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { mapState } from "vuex";
import AppBar from "./components/Appbar";
import Drawer from "./components/Drawer";
import FreshworksWidget from './components/FreshworksWidget.vue';


//import InstallButtonPwa from "./components/InstallButtonPwa";

function loadScript(a) {
  var b = document.getElementsByTagName("head")[0],
    c = document.createElement("script");
  (c.type = "text/javascript"),
    (c.src = "https://tracker.metricool.com/resources/be.js"),
    (c.onreadystatechange = a),
    (c.onload = a),
    b.appendChild(c);
}
loadScript(function() {
  window.beTracker.t({
    hash: "83867b028129c8bb5f3e111dc9538906",
  });
});

export default {
  components: {
    AppBar,
    Drawer,
    FreshworksWidget,
  },
  data: () => ({
    snackbar: false,
    snackbarVisible: false,
    drawer: false,
    done: false,
    dialogLogin: false,
    dialogRegister: false,
    dialogSMS: false,
    loading: false,
    nombre: "",
    apellido: "",
    dniRe: "",
    emailRe: "",
    phoneRe: "",
    rePais: "",
    reCuidad: "",
    passRe: "",
    promoCode: "",
    referido: "",
    passConRe: "",
    nacimientoRe: "",
    checkboxMayor: false,
    checkboxCond: false,
    email: "",
    pass: "",
    error: false,
    dominio: null,
    min: "2:00",
    interval: {},
    codeSMS: null,
    id: null,
    waiting: false,
    doneReset: false,
    telReset: [],
    emialReset: "",
    passReset: "",
    confPassReset: "",
    invalid: false,
    sms: false,
    moneda: "",
    deferredPrompt: null,
    saldoSelected: 0,
    mensaje: "",
    show1: false,
    rules: {
      required: (value) => !!value || "Requerido.",
      min: (v) => v.length >= 8 || "Min 8 Caracteres",
      emailMatch: () => `The email and password you entered don't match`,
      emailValid: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Ingrese un Email valido",
    },
  }),
  watch: {
    $route(to) {
      if (to.name == "Channels") {
        this.$store.state.dialogChannel = true;
      } else {
        this.$store.state.dialogChannel = false;
      }
    },
    dialogRegister(e) {
      if (!e) {
        this.nombre = "";
        this.emailRe = "";
        this.phoneRe = "";
        this.passRe = "";
        this.passConRe = "";
        this.checkboxMayor = false;
        this.checkboxCond = false;
        this.email = "";
        this.pass = "";
        this.promoCode = "";
        this.referido = "";
        this.error = false;
      }
    },
    dialogRules(e) {
      if (e) {
        setTimeout(() => {
          this.$refs.b.style.height = this.$refs.a.clientHeight + "px";
          this.$refs.d.style.height = this.$refs.c.clientHeight + "px";
        }, 100);
      }
    },
  },
  computed: {
    ...mapState({
      saldos: "saldos",
      loadingSaldo: "loadingSaldo",
      currentBalance: "currentBalance",
      currentBono: "currentBono",
    }),
    footer() {
      return this.$store.state.footer;
    },
    contryCurrency() {
      return this.$store.state.contryCurrency;
    },
    dialogProgramas: {
      get() {
        return this.$store.state.dialogProgramas;
      },
      set(value) {
        this.$store.state.dialogProgramas = value;
      },
    },
    dialogReset: {
      get() {
        return this.$store.state.dialogResetPass;
      },
      set(v) {
        this.$store.state.dialogResetPass = v;
      },
    },
    dialogRules: {
      get() {
        return this.$store.state.dialogRules;
      },
      set(v) {
        this.$store.state.dialogRules = v;
      },
    },
    size() {
      return this.$store.state.windowSize.x;
    },
    routeName() {
      return this.$route.name;
    },
    image() {
      return this.$store.state.image;
    },
    logo() {
      return this.$store.state.logo;
    },
    colors() {
      return this.$store.state.colors;
    },
    emailtr() {
      return this.$store.state.email;
    },
    marca() {
      return this.$store.state.marca;
    },
    cssProps() {
      return {
        "--table-head0": this.colors.gradient ? this.colors.gradient[0] : "",
        "--table-head1": this.colors.gradient ? this.colors.gradient[1] : "",
        "--select-0": this.colors.gradientBarra
          ? this.colors.gradientBarra[0]
          : "",
        "--select-1": this.colors.gradientBarra
          ? this.colors.gradientBarra[1]
          : "",
        "--table-back": this.colors.panelbar,
        "--table-even": this.colors.even,
      };
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.register == "1") {
        this.dialogRegister = true;
      }
    }, 100);
    //para el boton de instalar el pwa

    //const shouldAddScript = true; // Cambia esto según tu lógica

    if (this.dominio == "tar.la" || this.dominio == "www.tar.la") {
      this.getUserLocation();
    }

    if ("serviceWorker" in navigator && "PushManager" in window) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();

        //const JdeferredPrompt = e;
        this.deferredPrompt = e;
        this.snackbarVisible = false;

        /*const installButton = document.createElement("button");
        installButton.textContent = "Instalar Aplicacion";
        installButton.style.position = "fixed";
        installButton.style.top = "10px";
        installButton.style.left = "50%";
        installButton.style.transform = "translateX(-50%)";
        installButton.style.zIndex = "9999";
        installButton.style.padding = "10px 20px";
        installButton.classList.add("btn-grad");
        installButton.style.color = "black";
        installButton.style.border = "none";
        installButton.style.borderRadius = "5px";
        installButton.style.cursor = "pointer";*/

        //installButton.addEventListener("click", () => {
        this.deferredPrompt.prompt();

        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("App installed");
          } else {
            console.log("App installation declined");
          }

          //installButton.style.display = "none";
        });
        //});

        //document.body.appendChild(installButton);
      });
    }

    //////

    this.$store.state.token = sessionStorage.getItem("token");
    this.$store.state.multiplo = sessionStorage.getItem("multiplo");
    this.$store.state.unidad = sessionStorage.getItem("unidad");
    this.$store.state.idsb = sessionStorage.getItem("idsb");
    this.$store.state.idpos = sessionStorage.getItem("idpos");
    this.$store.state.pais_user = sessionStorage.getItem("pais_user");
    this.onResize();
    this.getToken();
    // this.initiateCall();
    //this.instalarPWA();
  },
  created() {
    this.getDominio();
    this.ax();
    this.colores();
    let localToken = localStorage.getItem("token");
    let sessionToken = sessionStorage.getItem("token");
    if (typeof xcm_190ad064_38a4_4a09_b70a_66ab3588750a !== "undefined") {
      this.initializeXcm();
      return;
    }

    if (sessionToken == null && localToken != null) {
      if (this.$route.path != "/") {
        this.$router.push({
          path: "/",
        });
      }

      if (localToken != null) {
        axios({
          method: "post",
          url: `https://trackandraces.com/onlineapi/api/logout`,
          headers: {
            Authorization: `Bearer ${localToken}`,
            accept: "application/json",
          },
        }).then((response) => {
          if (response.data.res) {
            this.$store.state.token = null;
            localStorage.removeItem("token");
          }
        });
        localStorage.removeItem("token");
      }
    }
  },
  methods: {
    ...mapActions(["getSaldo", "changeLoadingSaldo"]),
    changeWallet(id) {
      axios({
        method: "post",
        url: `/cambiar_cartera`,
        data: {
          idmoneda: id,
        },
      }).then(async () => {
        await this.getSaldo();
        this.saldoSelected = 0;
      });
    },
    selectSaldo(id) {
      this.changeLoadingSaldo(true);
      this.changeWallet(id);
    },
    open(link) {
      window.open(link, "_blank");
    },

    async getUserLocation() {
      try {
        const apiKey = '8ce7e66ed43549d0be2c01339fcd3304'; // Reemplaza con tu clave de API
        const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`);

        if (!response.ok) {
          throw new Error(`Error en la solicitud: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Datos de ubicación:', data);

        // Verificar si el usuario es de Latinoamérica
        const latamCountries = [
          'AR', 'BO', 'BR', 'CL', 'CO', 'CR', 'CU', 'DO', 'EC', 'SV',
          'GT', 'HN', 'MX', 'NI', 'PA', 'PY', 'PE', 'PR', 'UY', 'VE'
        ]; // Códigos de países de Latinoamérica

        if (latamCountries.includes(data.country_code2)) {
          console.log('ULT');
          this.addXcmScript();
        } else {
          console.log('UNLT');
        }
      } catch (error) {
        console.error('Error al obtener la ubicación del usuario:', error);
      }
    },

    addXcmScript() {
      // Verifica si el script ya existe
      const script = document.createElement('script');
      script.src = 'https://190ad064-38a4-4a09-b70a-66ab3588750a.seals-xcm.certria.com/xcm-seal.js';
      script.type = 'text/javascript';

      script.onload = () => {
        console.log('Script cargado correctamente.');
        this.initializeXcm(); // Llama a la inicialización después de cargar el script
      };

      script.onerror = () => {
        console.error('Error al cargar el script de XCM.');
      };

      document.head.appendChild(script);
    },

    initializeXcm() {
      
      const maxAttempts = 10; // Número máximo de intentos
      let attempts = 0;

      const checkXcm = () => {
        if (typeof window.xcm_190ad064_38a4_4a09_b70a_66ab3588750a !== 'undefined') {
          
          window.xcm_190ad064_38a4_4a09_b70a_66ab3588750a.init();
          console.log('XCM inicializado correctamente.');
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(checkXcm, 1000); // Reintentar después de 1 segundo
        } else {
          console.error('El objeto global de XCM no está definido después de varios intentos.');
        }
      };
      checkXcm();

    },
    // initFreshChat() {
    // 	window.fcWidget.init({
    // 		token: "39f0bd5f-ef38-4592-8d36-3142c8a30fb2",
    // 		host: "https://wchat.freshchat.com",
    // 	});
    // },
    // initialize(i, t) {
    // 	var e;
    // 	i.getElementById(t)
    // 		? this.initFreshChat(): (((e = i.createElement("script")).id = t),(e.async = !0),(e.src = "https://wchat.freshchat.com/js/widget.js"),(e.onload = this.initFreshChat),i.head.appendChild(e));
    // },
    // initiateCall() {
    // 	this.initialize(document, "freshchat-js-sdk");
    // },

    installPWA() {
      this.deferredPrompt.prompt();

      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("App installed");
        } else {
          console.log("App installation declined");
        }
      });
    },

    sendReset() {
      this.loading = true;
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/recuperar_clave`,
        data: {
          email: this.emialReset,
        },
      }).then((response) => {
        if (response.data.message == "Email no registrado") {
          this.invalid = true;
        } else {
          this.id = response.data.id;
          this.telReset = response.data.telefono;
          this.waiting = true;
          this.countdown();
        }
        this.loading = false;
      });
    },
    changePass() {
      this.loading = true;
      if (this.passReset == this.confPassReset) {
        axios({
          method: "post",
          url: `https://trackandraces.com/onlineapi/api/cambiar_clave_sms`,
          data: {
            email: this.emialReset,
            password: this.passReset,
          },
        }).then((response) => {
          this.dialogReset = false;
          this.$store.state.msg = response.data.message;
          this.snackbar = true;
          this.id = "";
          this.waiting = false;
          this.doneReset = false;
          this.telReset = [];
          this.emialReset = "";
          this.passReset = "";
          this.confPassReset = "";
          this.invalid = false;
          this.loading = false;
        });
      }
    },

    colores() {
      let phone = window.innerWidth <= 800;
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/dominio`,
        data: {
          dominio: this.dominio,
          phone: phone,
        },
      }).then((response) => {
        if (response.data.message == "Dominio no Registrado") {
          this.defaultTheme();
        } else {
          let value = JSON.stringify(response.data);
          sessionStorage.setItem("theme", value);
          let theme = sessionStorage.getItem("theme");
          let themeValue = JSON.parse(theme);
          this.$store.state.registro = themeValue.registro;
          this.$store.state.dominio = themeValue.domino;
          this.$store.state.marca = themeValue.nombre;
          this.$store.state.logo = themeValue.logo;
          this.$store.state.eslogan = themeValue.eslogan;
          this.$store.state.email = themeValue.email;
          this.$store.state.telefono = themeValue.twich;
          this.$store.state.carrusel = themeValue.carrusel;
          this.$store.state.colors = themeValue.paleta[0];
          this.$store.state.social = themeValue.social[0];
          this.$store.state.id_tw = themeValue.api_twitter;
          this.$store.state.logoW = themeValue.logoW;
          this.$store.state.logoName = themeValue.logoName;
          this.$store.state.footer = themeValue.footer;
          this.$store.state.contryCurrency = themeValue.paises;
          this.done = true;
          document.title = this.marca;
        }
      });
    },
    defaultTheme() {
      let theme = sessionStorage.getItem("theme");
      if (theme == null) {
        this.$store.state.registro = 0;
        this.$store.state.dominio = null;
        this.$store.state.marca = "Track & Races";
        this.$store.state.logo = require("./assets/img/logo.svg");
        this.$store.state.eslogan = "Registrate, Apuesta y Cobra seguro...!";
        this.$store.state.email = "info@trackandraces.com";
        this.$store.state.carrusel = [];
        this.$store.state.colors = {
          background: "#030338",
          error: "#ff6767",
          even: "#f7f8f9",
          gradient: ["#00a9ef", "#0056af"],
          gradientBarra: ["#019052", "#00dc77"],
          gradientHeader: ["#0f63b9", "#02a3e6"],
          header: "#fff",
          jugar: "#00d5e7",
          panelbar: "#fff",
          perfil: "#0097f7",
          primary: "#00b3f5",
          reglas: "#c2d0da",
          sidebar: "#f7f8f9",
          success: "#66bb6a",
          warning: "#ffd300",
        };
        this.done = true;
      } else {
        // this.colores();
      }
    },
    dLogin() {
      this.dialogLogin = true;
    },
    dRegister() {
      axios
        .get(
          "https://apiip.net/api/check?accessKey=ea88063e-e8f7-4a1d-8e50-6eb584e767ec"
        )
        .then((e) => {
          this.rePais = e.data.countryName;
        });
      this.dialogRegister = true;
      document.getElementById("frmregister").reset();
    },
    update(e) {
      this.drawer = e;
    },
    navDrawer() {
      this.drawer = true;
    },
    resendSMS() {
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/resend_sms`,
        data: {
          id: this.id,
        },
      }).then((response) => {
        response;
        this.min = "2:00";
        this.countdown();
      });
    },
    sendSMS(e) {
      e.preventDefault();
      this.loading = true;

      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/registro_sms`,
        data: {
          id: this.id,
          codigosms: this.codeSMS,
        },
      }).then((response) => {
        if (response.data.message == "El codigo ingresado es Incorrecto") {
          this.error = true;
        } else {
          this.error = false;
          this.$store.state.msg = response.data.message;
          this.snackbar = true;
          this.codeSMS = null;
          this.dialogSMS = false;
          this.min = "2:00";
          this.nombre = "";
          this.emailRe = "";
          this.phoneRe = "";
          this.passRe = "";
          this.passConRe = "";
          this.checkboxMayor = false;
          this.checkboxCond = false;
          this.email = "";
          this.pass = "";
          this.waiting = false;
          this.doneReset = true;
        }
        this.loading = false;
      });
    },
    countdown() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        var timer = this.min;
        timer = timer.split(":");
        var minutes = timer[0];
        var seconds = timer[1];
        seconds -= 1;
        if (minutes < 0) return;
        else if (seconds < 0 && minutes != 0) {
          minutes -= 1;
          seconds = 59;
        } else if (seconds < 10 && length.seconds != 2) seconds = "0" + seconds;

        this.min = minutes + ":" + seconds;

        if (minutes == 0 && seconds == 0) clearInterval(this.interval);
      }, 1000);
    },
    onInput(formattedNumber, { number }) {
      this.phoneRe = number.international;
      this.telRe = number.significant;
    },
    getDominio() {
      let domName = document.location.hostname;
      if (domName != "localhost") {
        if (domName.split(".")[0] == "wwww") {
          this.dominio = domName.split(".")[1] + domName.split(".")[2];
        } else {
          this.dominio = domName;
        }
      } else {
        this.dominio = "trackandraces.com";
      }
    },
    ax() {
      axios.interceptors.request.use(
        (config) => {
          const token = this.$store.state.token;
          if (token) {
            config.baseURL = "https://trackandraces.com/onlineapi/api/";
            config.headers["Authorization"] = "Bearer " + token;
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );
      axios.interceptors.response.use(undefined, (error) => {
        if (error.response.status === 401) {
          this.$store.state.token = null;
          this.$router.replace("/");
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");

          return Promise.reject(error);
        }
      });
    },
    getToken() {
      this.$store.state.token = localStorage.getItem("token");
    },
    register(e) {
      e.preventDefault();

      if (
        this.passRe &&
        this.checkboxMayor &&
        this.checkboxCond &&
        this.moneda != ""
      ) {
        let split = this.phoneRe.split("+")[1];
        let area = split.split(" ")[0];
        let phone = this.telRe;

        this.loading = true;
        axios({
          method: "post",
          url: `https://trackandraces.com/onlineapi/api/web_register`,
          data: {
            name: this.nombre + " " + this.apellido,
            email: this.emailRe,
            area: area,
            telefono: phone,
            clave: this.passRe,
            dominio: this.dominio,
            idpais: this.moneda,
            pais: this.rePais,
            ciudad: this.reCuidad,
            dni: this.dniRe,
            f_nacimiento: this.nacimientoRe,
            promocode: this.promoCode,
            referido: this.referido,
          },
        }).then((response) => {
          if (response.data.message == "Mensaje enviado") {
            this.id = response.data.id;
            this.dialogSMS = true;
            this.dialogRegister = false;
            this.countdown();
          } else {
            this.$store.state.msg = response.data.message;
            this.snackbar = true;
          }
          this.loading = false;
        });
      } else {
        this.error = true;
      }
    },
    login() {
      this.loading = true;
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/login`,
        data: {
          email: this.email,
          password: this.pass,
        },
      }).then((response) => {
        //console.log(response);
        if (response.data.res == true) {
          sessionStorage.setItem("token", response.data.token);
          localStorage.setItem("token", response.data.token);
          this.$store.state.token = response.data.token;
          this.$store.state.multiplo = response.data.multiplo;
          sessionStorage.setItem("multiplo", response.data.multiplo);
          this.$store.state.unidad = response.data.unidad;
          sessionStorage.setItem("unidad", response.data.unidad);
          this.$store.state.idsb = response.data.idsb;
          sessionStorage.setItem("idsb", response.data.idsb);
          this.$store.state.idpos = response.data.idpos;
          sessionStorage.setItem("idpos", response.data.idpos);
          this.$store.state.idmoneda = response.data.idmoneda;
          sessionStorage.setItem("idmoneda", response.data.idmoneda);
          this.$store.state.tyc = response.data.registro;
          sessionStorage.setItem("tyc", response.data.registro);
          this.loading = false;
          this.email = "";
          this.pass = "";
          this.$store.state.nombre = response.data.nombre;
          this.$store.state.emailUser = response.data.email;
          this.$store.state.cedula = response.data.cedula;
          this.$store.state.codreferido = response.data.codreferido;
          sessionStorage.setItem("codreferido", response.data.codreferido);
          this.$store.state.pais_user = response.data.pais;
          sessionStorage.setItem("pais_user", response.data.pais);
          this.error = false;
          this.dialogLogin = false;
          if (
            response.data.idsb == 13 ||
            response.data.idsb == "13" ||
            response.data.idsb == 8 ||
            response.data.idsb == "8"
          ) {
            this.$router.push({
              path: "/gametype",
            });
          } else {
            this.$router.push({
              path: "/bet",
            });
          }
        } else {
          this.loading = false;
          this.error = true;
          this.mensaje = response.data.message;
          this.pass = "";
        }
      });
    },
    onResize() {
      this.$store.state.token = sessionStorage.getItem("token");
      this.$store.state.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      };
    },

    logout() {
      axios({
        method: "post",
        url: `https://trackandraces.com/onlineapi/api/logout`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          accept: "application/json",
        },
      }).then((response) => {
        if (response.data.res) {
          this.$store.state.token = null;
          this.$router.replace("/");
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
        }
      });
    },
  },
};
</script>

<style>
html {
  overflow-y: overlay;
}

.color {
  color: #32325d;
}

.muted {
  color: #8898aa !important;
}

.v-overlay.v-overlay--active > .v-overlay__scrim {
  background-color: rgba(55, 55, 55, 0.6) !important;
  opacity: 1 !important;
}

.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
.v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
  margin: 2px 0 !important;
}

.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none !important;
}

.horse table thead tr th {
  background: linear-gradient(
    0deg,
    var(--table-head1) 0%,
    var(--table-head0) 100%
  ) !important;
}

table thead th {
  background: transparent !important;
  height: 36px !important;
  font-size: 0.68rem !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
}

table thead th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

table thead th:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

table thead th span {
  position: relative;
  top: 0.12rem;
}

table td,
table th {
  font-size: 0.8125rem !important;
}

.table-profile tr:nth-child(even) {
  background: #f5f5f5;
}

.theme--light.v-card,
.theme--light.v-btn {
  color: #5d7484 !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #fff !important;
}

.theme--light.v-data-table {
  color: #243542 !important;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th {
  background: var(--table-head) !important;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.theme--dark.v-data-table.v-data-table--fixed-header thead th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.horse {
  background: #fff !important;
  border-radius: 5px !important;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ffffff41 !important;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid transparent !important;
}

.horse tr:nth-child(even) {
  background: var(--table-even) !important;
}

.retired td:not(.check) {
  opacity: 0.3;
}

.todo-text {
  position: relative;
  top: 4.5px;
  z-index: 99;
  font-size: 8.5px;
}

.todo-text.active {
  color: #fff;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 8px -5px rgba(150, 150, 150, 0.2),
    inset 0px -4px 8px -5px rgba(150, 150, 150, 0.2) !important;
}

input {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
}

.v-list .v-divider.v-divider--inset.theme--dark:not(.v-divider--vertical) {
  margin-left: 64px !important;
}

.v-btn {
  font-size: 0.84rem !important;
  letter-spacing: 0.8px !important;
}

@media (min-width: 960px) {
  .v-data-table__wrapper::-webkit-scrollbar {
    width: 6px !important;
    background: #0000000a !important;
    border-radius: 30px;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #ccc !important;
    border-radius: 30px;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
    background: #bebebe !important;
  }
}

.v-application--is-ltr .v-data-table--fixed-header .v-data-footer {
  margin-right: 0px !important;
}

.phone {
  background: #eceff1;
  border: none !important;
}

.phone input {
  padding-left: 0px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.register input,
.register label {
  margin-left: 16px !important;
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
}

.backimg {
  background-image: url("./assets/img/fondo derecha.svg");
  background-repeat: repeat;
  background-blend-mode: luminosity;
  background-position: center;
}

.animate-mtp {
  animation-duration: 0.6s;
  animation-name: leave;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  padding: 3px;
  border-radius: 6px;
  background: rgb(255, 60, 60);
}

.p-ab {
  position: absolute !important;
}

@keyframes leave {
  0% {
    transform: scale(0.96);
  }

  100% {
    transform: scale(1.1);
  }
}
</style>
