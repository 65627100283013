<template>
  <div class="freshworks-widget-container">
    <!-- El widget se inyectará aquí -->
  </div>
</template>

<script>
export default {
  name: 'FreshworksWidget',
  props: {
    widgetId: {
      type: String,
      default: '12166505/4659305' // Tu ID de widget
    },
    enableChat: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.loadFreshworksWidget();
  },
  methods: {
    loadFreshworksWidget() {
      // Verificar si el widget ya está cargado
      if (window.fwSettings) {
        console.log('Freshworks widget ya está cargado');
        return;
      }

      // Crear el script
      const script = document.createElement('script');
      script.src = `//fw-cdn.com/${this.widgetId}.js`;
      script.async = true;
      script.setAttribute('chat', this.enableChat.toString());
      
      // Manejar errores
      script.onerror = () => {
        console.error('Error al cargar el widget de Freshworks');
      };
      
      // Agregar al documento
      document.body.appendChild(script);
    }
  },
  beforeDestroy() {
    // Limpieza si es necesaria
    const scripts = document.querySelectorAll(`script[src="//fw-cdn.com/${this.widgetId}.js"]`);
    scripts.forEach(script => script.remove());
    
    // Eliminar cualquier elemento del widget si es necesario
    const widgetElements = document.querySelectorAll('.freshworks-container');
    widgetElements.forEach(el => el.remove());
  }
}
</script>

<style scoped>
.freshworks-widget-container {
  /* Estilos si son necesarios */
}
</style>